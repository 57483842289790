import React from 'react';
import Layout from 'layout';
import 'sass/theme/terms.scss';

const PP = () => (
  <Layout
    metaTitle="Privacy Policy | Session Replay App"
    canonical="/privacy-policy/"
    metaDescription="We care about your privacy. Read LiveSession’s privacy policy. "
  >
    <section className="terms">
      <h1>LIVESESSION DATA PROTECTION POLICY</h1>

      <h2>PREFACE</h2>
      <p>
        For LiveSession sp. z o.o. (Ltd.) one of the most important value is the protection of your
        personal data as well as your privacy. Thus, for the purposes of providing you with our
        website services and software-as-a-service, we decided to be bound by the principles
        included in this Protection Policy.{' '}
      </p>
      <p>
        Please read this Policy carefully as it defines basic principles and mechanisms of how we
        process your personal information. This is a legal document, yet, we have tried to prepare
        it in a clear and transparent manner in order to enhance protection of your rights,{' '}
      </p>

      <p>
        <strong>
          This Data Protection Policy (&quot;Policy&quot;) has been prepared by the LiveSession sp.
          z o.o. (Ltd.) with its registered office in Wroclaw, Poland. (&quot;LiveSession&quot;,
          &quot;us&quot;, &quot;we&quot;, or &quot;our&quot;).
        </strong>
      </p>
      <p>
        <strong>
          The hereby Data Protection Policy applies to the LiveSession website and its users or
          visitors and any software-as-a-service being provided by the LiveSession (”SaaS”), defined
          in detail in an applicable SaaS Agreement, if any, between LiveSession and Client („you”
          or „your”).
        </strong>
      </p>
      <p>
        <strong>
          PLEASE KINDLY NOTE THAT THIS DOCUMENT PRIMARILY REGARDS THE RELATIONSHIP BETWEEN
          LIVESESSION AND OUR CLIENTS. IF YOU ARE A CUSTOMER OF OUR CLIENT - WHO USES OUR SAAS ON
          HER OR HIS WEBSITE - PLEASE KINDLY REFER TO THE SECTION ‘A WEBSITE I VISIT USES
          LIVESESSION SAAS’.
        </strong>
      </p>

      <p>
        Our SaaS service is available to customers from various countries of the World, including
        states which are part of the European Union (the EU) or the European Economic Area (the
        EEA). In accordance with the so-called General Data Protection Regulation (the GDPR), which
        is the act forming part of the European Union law, in order to provide services to our
        Clients from Europe, we are obliged to inform about a number of issues, this is directly
        required of us by the law.
      </p>
      <p>
        In order to clarify we would like to indicate that you could use the principles of the GDPR
        if you use the SaaS in the EEA, or if you are a citizen of one of the EEA states. In the
        following part of this Policy, all persons covered by the GDPR principles on processing of
        personal data are going to be jointly referred to as “<strong>the EU persons</strong>
        ”. Should you have any doubts about your rights - please do not hesitate to contact us, we
        are here to help you!
      </p>
      <p>
        If the GDPR does not concern you, we strongly invite you to carefully read the entire Data
        Protection Policy document. Even if the GDPR does not apply in your case (as, for example,
        due to the fact that you are the citizen and resident of the US and you use our SaaS service
        there), we would like to protect your privacy and personal information just as well and
        safely.
      </p>

      <h2>SIGNIFICANT CONCEPTS</h2>
      <p>
        Be aware that on the relevant legal acts, our Protection Policy, as well as in other
        documents that we may apply while processing data, there is a number of concepts important
        to the protection of your rights. Depending on the country in which you use our SaaS, the
        manner of understanding the following concepts, essential for the protection of your
        privacy, may vary.{' '}
      </p>
      <p>
        <strong>
          By processing your personal data for the SaaS purposes, we understand the following key
          concepts as follows:
        </strong>
      </p>

      <p>
        <strong>PERSONAL DATA</strong> - means any information relating to an identified or
        identifiable natural person. The identifiable natural person is the one who can be
        identified, directly or indirectly, in particular by reference to an identifier such as a
        name and surname, an identification number, location data, an online identifier or to one or
        more factors specific to the physical, physiological, genetic, mental, economic, cultural or
        social identity of that natural person.
      </p>
      <p>
        <strong>
          The term &quot;personal information&quot; is most commonly used in the US and Canada in
          order to indicate personal data. Further, in this document, we would like to use the term
          &quot;personal data&quot; or simply &quot;data&quot; uniformly.
        </strong>
      </p>
      <p>
        Typical examples of personal data are as follows: home and work addresses, telephone number,
        e-mail address, social security number, birthdate, gender, marital status, mother&quot;s
        maiden name, and health data.
      </p>
      <p>
        <strong>PROCESSING OF DATA</strong> - means any operation or set of operations which is
        performed on personal data or on sets of personal data, whether or not by automated means.
        Processing of data involves in particular: collection, recording, organization, structuring,
        storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission,
        dissemination or otherwise making available, alignment or combination, restriction, erasure
        or destruction of personal data;
      </p>
      <p>
        <strong>CONTROLLER OF PERSONAL DATA</strong> - within the framework of the EU law means the
        natural or legal person, public authority, agency or another body which, alone or jointly
        with others, determines the purposes and means of the processing of personal data; where the
        purposes and means of such processing are determined by the European Union or Member State
        law, the controller or the specific criteria for its nomination may as well be provided for
        by the European Union or Member State law;
      </p>
      <p>
        <strong>
          The controller processing personal data for the purpose of providing our website and SaaS
          services is the LiveSession sp. z o.o. (Ltd.) with its registered office in Wroclaw,
          Poland, Gen. W. Sikorskiego Street no. 3/2, 53-659 Wroclaw,{' '}
        </strong>
        <strong>Poland</strong>
        <strong>.</strong>{' '}
        <strong>
          In all matters concerning the protection of your privacy and personal data you are welcome
          to contact us through the following contact detail
        </strong>
        <strong>s: </strong>
        <strong>privacy@livesession.io</strong>
      </p>
      <p>
        At the same time, we would like to kindly inform persons from the EU that we have not
        appointed a data protection officer (see: Articles 37-39 of GDPR).
      </p>
      <p>
        <strong>THE BASIS OF DATA PROCESSING</strong> - legally defined grounds for the processing
        of personal data by us. In principle, we process your data on the basis of your consent, or
        because we need it to provide you with the website or SaaS service or in order to make a
        settlement of the agreement we concluded.
      </p>
      <p>
        It may happen that we would be forced by law to transfer your personal data to public
        services - yet we always remain committed to acting in accordance with the law. We are
        allowed to use your data to develop the LiveSession through, for example, customer profile
        analysis, preparation of marketing strategies. In this case, the basis for the processing of
        your data are our legitimate business interests - the possibility to make market analysis,
        advertising, implementation of sales strategies, etc. as it remains a part of the
        fundamental right of economic freedom and the freedom to conduct a business. Nevertheless,
        we renounce such processing which would excessively interfere your rights and freedoms. In
        the case of EU citizens, the legal grounds for data processing of personal data are
        explicitly set forth in the GDPR. In the case of our SaaS, depending on the circumstances
        that would be the following:
      </p>
      <ol type="1">
        <li>
          Article 6(1)(a) of GDPR - the data subject has given consent to the processing of his or
          her personal data for one or more specific purposes;{' '}
        </li>
        <li>
          Article 6(1)(b) of GDPR - processing is necessary for the performance of the agreement to
          which the data subject is party or in order to take steps at the request of the data
          subject prior to entering into the agreement;
        </li>
        <li>
          Article 6(1)(c) of GDPR - processing is necessary for compliance with a legal obligation
          to which the controller is subject;
        </li>
        <li>
          Article 6(1)(f) of GDPR - processing is necessary for the purposes of the legitimate
          interests pursued by the controller or by a third party, except where such interests are
          overridden by the interests or fundamental rights and freedoms of the data subject which
          require protection of personal data, in particular where the data subject is a child.
        </li>
      </ol>
      <p>
        <strong>PROFILING</strong> - means any form of automated processing of personal data
        consisting of the use of personal data to evaluate certain personal aspects relating to
        natural person, (in particular to analyze or predict aspects concerning that natural
        person&quot;s performance at work, economic situation, health, personal preferences,
        interests, reliability, behavior, location or movements). Examples of profiling are, e.g.
        automatic credit rating or displaying advertisements based on previous Internet activity.
      </p>
      <p>
        Currently, we do not use your personal data to make automated decisions using the available
        technologies. If we change it in the future, we will update this Policy to let you know
        more.
      </p>
      <p>
        <strong>PROCESSOR</strong> - means a natural or legal person, public authority, agency or
        another body which processes personal data on behalf of the controller. It may happen that
        the controller of your data, acting legally, entrusts data to third party.
      </p>
      <p>
        Acting in compliance with the law, we may transfer your personal data to our contractors and
        service providers. We always provide at least the same level of security of your data and
        are constantly committed to choosing our contractors who can guarantee a high level of
        protection of your privacy.
      </p>
      <p>
        The controller of your personal data is LiveSession, however, it may transfer personal data
        to its affiliates. We are also allowed to transfer data to entities such as companies
        providing accounting and tax services, our lawyers, payment companies, banks, companies
        providing analytical services (e.g. for the purposes of market analysis) or marketing and PR
        services.{' '}
      </p>
      <p>
        <strong>CONSENT TO DATA PROCESSING</strong> - extremely significant concept, as most
        frequently we process your data based on your consent. Consent of the data subject means any
        freely given, specific, informed and unambiguous indication of the data subject&quot;s
        wishes by which he or she, by a statement or by a clear affirmative action, signifies
        agreement to the processing of personal data relating to him or her.
      </p>
      <p>
        Please remember that your consent to the processing of your personal data by us is and would{' '}
        <strong>always remain voluntary</strong>. You can also withdraw your consent at any time,
        however without your consent to data processing, we might be unable to provide you with our
        website and SaaS services.
      </p>

      <h2>LAW</h2>
      <p>
        The principles of protection of your personal data and privacy may result from both states
        as well as federal law.{' '}
      </p>
      <p>
        In the case of the EU citizens, the principles for the processing of personal data arise
        primarily from the so-called General Data Protection Regulation. It is an act of the EU law,
        which means that it is a regulation common to all of the EU Members. The same principles
        apply to all of the EU entrepreneurs.{' '}
      </p>
      <p>
        The full name of this act is as follows: Regulation of the European Parliament and of The
        Council (EU) 2016/679 of 27 April 2016 on the protection of natural persons with regard to
        the processing of personal data and on the free movement of such data, and repealing
        Directive 95/46/EC (General Data Protection Regulation) (GDPR).
      </p>

      <h2>AIMS AND TYPES OF DATA PROCESSED FOR PROVIDING SAAS SERVICES</h2>
      <p>
        In our organization, we process a variety of data and different categories of personal data
        – all for specific purposes. If we ask you for consent to process information, we inform you
        about the processing principles. Please do not hesitate to familiarize yourself with them
        carefully.
      </p>
      <p>
        Please remember that your consent is always voluntary and you can withdraw your consent at
        any time without stating any reason. Please note, however, that occasionally it may turn out
        to be impossible to provide you with services, fulfill your order or provide some SaaS
        functionalities - as it might not be possible without your personal data. Therefore, you are
        kindly asked to fully consider whether you consciously would like to authorize us to process
        your personal data.
      </p>
      <p>
        <strong>
          By using the LiveSession website and SaaS services, you consent to the processing of your
          personal data for the purpose of providing services by us.{' '}
        </strong>
      </p>
      <p>
        In the case of our website and SaaS services, we collect and process the following data for
        the purposes indicated below:
      </p>

      <div className="scrollbox">
        <table>
          <thead>
            <tr>
              <th>SOURCE OF DATA</th>
              <th>TYPE OF DATA </th>
              <th>PURPOSE</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>website contact form, any other communications between us</td>
              <td>required: name, surname, e-mail address; optional: telephone number</td>
              <td>
                communications with you, negotiations of contracts, presentation of our offers and
                services, handling your requests
              </td>
            </tr>
            <tr>
              <td>any agreement between you and LiveSession</td>
              <td>
                name and surname (if applicable), business name, contact address, email address, tax
                ID
              </td>
              <td>execution and performance of an agreement</td>
            </tr>
            <tr>
              <td>data collected during the use of website and SaaS services</td>
              <td>
                IP address, number of pages visited at the website, time spent on particular pages,
                any server requests, cursor position
              </td>
              <td>analytical purposes</td>
            </tr>
            <tr>
              <td>cookies files (please see the information below)</td>
              <td>identity of website and SaaS users</td>
              <td>user identification, authentication and authorization during the session</td>
            </tr>
            <tr>
              <td>your activity on our website</td>
              <td>
                your online behaviour expressed throughout clicks and action undertaken while
                visiting ou1r website; your online behaviour directly presents the information on:
                your email, IP, command of languages, your location, device you use, your interests,
                your familiarity with our product. Indirectly it may however, after analysis, be a
                source on information on your needs, personality and many other details of life and
                work
              </td>
              <td>analytics for the purpose of right targeting, development of our products</td>
            </tr>
          </tbody>
        </table>
      </div>

      <h2>BASIC PRINCIPLES OF PERSONAL DATA PROTECTION</h2>
      <p>
        Processing of your personal data may each time look different, depending on what data we
        process, for what purpose, by what means, on what legal basis, etc. In each case, however,
        we are guided by a few fundamental values and principles:
      </p>

      <ol type="i">
        <li>
          <strong>LAWFULNESS</strong> – we always process your data in accordance with the
          applicable law;
        </li>
        <li>
          <strong>RELIABILITY</strong> – we process your data reliably, in the organized and
          thoughtful manner;
        </li>
        <li>
          <strong>TRANSPARENCY</strong> – we are committed to making the data processing processes
          transparent;
        </li>
        <li>
          <strong>PURPOSEFULNESS</strong> – we always collect and process data for a specific legal
          purpose or purposes; we do not collect data unnecessarily;{' '}
        </li>
        <li>
          <strong>ADEQUACY</strong> – we process data adequate to the purposes for which we do it;
          we limit the processing of data to what is necessary to achieve a specific purpose beyond
          which we do not cross;
        </li>
        <li>
          <strong>CORRECTNESS</strong> – we take reasonable care to process only personal data which
          are correct and up-to-date;{' '}
        </li>
        <li>
          <strong>LIMITATION OF STORAGE</strong> – in accordance with the GDPR, storage in a form
          which permits identification of data subjects for no longer than is necessary for the
          purposes for which the personal data are processed; we store personal data no longer than
          reasonably needed;
        </li>
        <li>
          <strong>INTEGRITY AND CONFIDENTIALITY</strong> – we process personal data in the manner
          that ensures appropriate security of the personal data, including protection against
          unauthorized or unlawful processing and against accidental loss, destruction or damage. We
          use appropriate technical or organizational measures;
        </li>
        <li>
          <strong>ACCOUNTABILITY</strong> – the controller of your data is responsible for
          compliance with the rules listed above. We keep records of how we process your personal
          data in order to demonstrate that, if necessary.{' '}
        </li>
      </ol>

      <h2>INFORMATION ON YOUR RIGHTS (GDPR)</h2>
      <p>
        The GDPR regulation confers persons from the European Union with a number of rights that
        they can use while we process their personal data. If you are the person from the EU or EEA
        you are vested in with the following rights:
      </p>
      <ol type="a">
        <li>
          the right to access and receive copies of your data. You have the right to receive from us
          one copy of your personal data, which we process, and another – for a fee;
        </li>
        <li>the right to rectify (to amend) your personal data;</li>
        <li>
          the right to erase data. If you think there are no grounds for us to process your data
          further and you are right, you can demand erasure;{' '}
        </li>
        <li>
          limitations on data processing. If you think that we have inaccurate data about you, and
          you do not request to erase these data, you can demand that we limit ourselves only to
          store this data, or to other undertake other activities that we would agree with you;
        </li>
        <li>the right to object to the processing of personal data;</li>
        <li>the right to data portability;</li>
        <li>
          if we process your data on the basis of your consent, you have the right to withdraw your
          consent for processing at any time without giving any reason. This does not affect the
          legality of the previous processing;
        </li>
        <li>the right to complain to the appropriate supervisory authority for our actions.</li>
      </ol>

      <p>
        In order to exercise your rights, you must first let us know. As a first step, you are
        kindly requested to contact in a convenient way for you with LiveSession - the controller of
        your personal data.{' '}
      </p>
      <p>
        Regardless of how, when and in what form you would like to contact LiveSession in matters
        relating to your privacy, please do not hesitate to read the following information,
        containing the rules for handling inquiries from people from the European Union resulting
        from the provisions of the GDPR, which we would use:
      </p>

      <p>
        Information shall be provided in writing or otherwise, including, where appropriate,
        electronically. In case of explicit request of the data subject, the information shall be
        given orally, provided that the identity of the data subject is confirmed by other means.
        The controller shall refuse to request if the identification of the data subject is not
        possible. The controller without undue delay – and in any case within one month from receipt
        of the request – provides the data subject with the information on actions taken in
        conjunction with the request. If necessary, this period may be extended by another two
        months due to the complex nature of the request or the number of requests. Within one month
        from the receipt of the request, the controller shall inform the data subject about such
        extension, stating the reasons for the delay. If the data subject has forwarded his request
        electronically, if possible, the information is also transmitted electronically, unless the
        data subject requests a different form. If the controller fails to take action in relation
        to the request of the data subject, the controller shall immediately – no later than within
        one month from the receipt of the request – inform the data subject of the reasons for
        failure to take action and the possibility of lodging a complaint to the supervisory
        authority and to exercise available legal remedies before the court. Information provided by
        the controller as well as communication and actions taken in conjunction with handling
        requests are free of charge. If the data subjects&quot; requests are manifestly unjustified
        or excessive, in particular, because of their continuing nature, the controller shall charge
        a reasonable fee, including administrative costs of providing information, communication or
        undertaking specific actions, or refusing to take actions in relation to the request. If the
        controller has reasonable doubts regarding the identity of the natural person submitting the
        request, the additional information necessary to confirm the identity of the data subject
        shall be requested.
      </p>

      <h2>INFORMATION ON YOUR RIGHTS (PERSONS FROM OUTSIDE OF THE EU AND EEA)</h2>
      <p>
        We would like to look after the security of all our Clients, website visitors and SaaS users
        equally. If the provisions of GDPR do not apply to you, you can still request that your
        personal data to be rectified if they are incorrect; to abandon processing them - if there
        are no grounds for it; to amend personal data - if they are changed. Should you like to
        amend anything or you simply wish to find out more, please do not hesitate to contact
        LiveSession.
      </p>

      <h2>PROCESSING OF PERSONAL DATA OF CHILDREN</h2>
      <p>
        Our website and SaaS services are designed and reserved for Clients who are adults only. It
        is the law of the country you are a citizen of which determines if you are an adult.
        Usually, depending on the country you are the citizen of, you must be at least 18 or 21
        years old in order to be able to use our services. If you are not an adult – you are not
        allowed to enter into any SaaS Agreement with us on the basis of which you would be provided
        with SaaS services. Under no circumstances, such agreements shall be concluded by persons
        who are not at least 16 years old. We do not collect or process personal data of children,
        including, in particular, personal data of persons under 16 years of age.
      </p>
      <p>
        Please be advised however that if you implement our tracking tool into your website you may
        be collecting children personal information and data, if a child visits your website. This
        can have certain implication on your legal duties, depending on your jurisdiction. Please
        note that our SaaS does not collect direct information on website’s user age.
      </p>

      <h2>COOKIES</h2>
      <p>
        As part of our Internet services and SaaS services, we are allowed to use cookies. It is a
        standard practice. These are, in principle, small files saved in the memory of your device
        which you are using while visiting our website and using our SaaS. Cookies collect various
        information, which - depending on their character or content - may enable us to perform a
        number of actions, e.g. automatic login to the website, automatic filling out of forms,
        return to the place on the website where the reading has ended, etc.
      </p>
      <p>Information about the cookies we use on our website and in our SaaS services: </p>
      <p>Cookies used by LiveSession tracking code: </p>
      <div className="scrollbox">
        <table>
          <thead>
            <tr>
              <th>Cookie name</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>__ls_uid</td>
              <td>This cookie is used to keep track of a visitor&quot;s identity.</td>
            </tr>
            <tr>
              <td>__ls_sid</td>
              <td>This cookie identifies the session.</td>
            </tr>
            <tr>
              <td>__ls_exp</td>
              <td>Timestamp for session expiration time.</td>
            </tr>
            <tr>
              <td>__ls_off</td>
              <td>
                If this cookie value is set to 1, tracking script will be disabled. This cookie is
                used due to our opt-out policy.
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <p>Cookies used by livesession.io:</p>

      <div className="scrollbox">
        <table>
          <thead>
            <tr>
              <th>Cookie name</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>__lsw_session</td>
              <td>
                This cookie stores information about the first page visited on website, referrer and
                promo code for our checkout.
              </td>
            </tr>
            <tr>
              <td>__lsw_visitor</td>
              <td>
                This cookie stores unique ID of visitor for LiveSession Partners program analytics.
              </td>
            </tr>
            <tr>
              <td>__lsw_afi</td>
              <td>Affiliate ID for revenue sharing attribution purpose.</td>
            </tr>
            <tr>
              <td>__lsw_last_source</td>
              <td>This cookie stores information about last UTM parameters.</td>
            </tr>
          </tbody>
        </table>
      </div>

      <p>Third party cookies providers on livesession.io: </p>

      <div className="scrollbox">
        <table>
          <thead>
            <tr>
              <th>Provider</th>
              <th>How do we use it</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>HubSpot</td>
              <td>
                We use it to identify customers and subscribers, enable live chat on website, and
                provide users with support forms
              </td>
            </tr>
            <tr>
              <td>AutoPilot</td>
              <td>
                We use it for email newsletter subscription forms and subscribers activity tracking
                - to adjust email campaigns to users interests
              </td>
            </tr>
            <tr>
              <td>Facebook</td>
              <td>We use tracking pixel for Facebook Ads remarketing</td>
            </tr>
            <tr>
              <td>Google Analytics</td>
              <td>
                We use it to get website conversion rate, user retention and traffic statistics
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <h2>MAINTAINING OUR PERSONAL DATA PROTECTION SYSTEM. NEW PRODUCTS, SERVICES, AND ACTIONS</h2>
      <p>
        If we would like to make changes to the operation of the SaaS or website, we commence to
        offer new products, services, we are willing to change the way we process your personal
        data, there may appear a need to review our existing data protection principles. We monitor
        how our activities may affect the security of your privacy. In particular, if we predict
        that our activities may in some way affect your privacy, we carry out appropriate risk
        assessments (Privacy Risk Assessment). If we create new products, services, and
        implementations of new configurations and settings of the SaaS – we accept such
        configurations and settings that do not expose you to the processing of personal data beyond
        what is necessary to use our solutions (Privacy by Default), whereas the products and
        services themselves are created according to the same principle (Privacy by Design). If we
        make changes to our functioning, and if there exists such a need, we also audit our data
        protection principles - in order to enhance the protection of information about you.
      </p>

      <h2>WHO IS BOUND BY THIS DATA PROTECTION POLICY</h2>
      <p>
        We strive to familiarize all our staff with this policy, in particular, those who have
        access to any personal data. Our employees and fellows are obliged to observe rules and
        principles which we apply in order to protect your data and we are committed to process your
        data with full respect of the law and in accordance with the main principles of our Policy
        indicated above. Only selected employees of the LiveSession have access to your data.{' '}
      </p>
      <p>
        We follow these principles, you use them. Just like we created our SaaS services for you.
      </p>

      <h2>TRANSFER OF DATA TO THIRD COUNTRIES AND INTERNATIONAL ORGANIZATIONS</h2>
      <p>
        The LiveSession is the company incorporated under the Polish law. The data you provide to us
        shall be processed primarily in Poland. LiveSession may also have affiliates or business
        partners in various countries around the World. In this case, your data can also be
        processed in the states other than Poland. Particularly, we cooperate with Google and use
        their servers in Iowa, the U.S. In addition, the SaaS is a technologically complex service.
        For its correct or enhanced operation, we can take advantage of various possibilities
        offered by technology and IT infrastructure, which may involve the temporary transfer of
        your data to servers, end devices, etc. located in other countries.
      </p>
      <p>
        No matter where your data is processed, we are striving to provide an equal level of data
        security everywhere. In particular, we select our contractors - providers of infrastructure
        and IT services - choosing only those who can guarantee the high level of protection of your
        privacy.
      </p>

      <h2>SECURITY OF YOUR DATA AND DATA RETENTION</h2>
      <p>
        We use appropriate to the level of risk (which may involve the processing of your data)
        technological, organizational and physical safeguards. Depending on the circumstances, we
        may use different types of security: IT security, encryption, pseudonymization, physical
        security or well-organized internal principles of processing of personal data only by
        concretely authorized persons. We protect your data in particular against accidental loss,
        modification or unauthorized disclosure to third parties. We protect your data best as we
        can.
      </p>
      <p>
        We store and process your data for as long as it is necessary for the purposes for which we
        do it. We might be obliged by law to keep data for a specific minimum period - we comply
        with such requirements. In principle, we process your data as long as it is necessary to
        provide and settle the SaaS or other services.
      </p>

      <h2>PERSONAL DATA BREACH</h2>
      <p>
        In the case where there would be a breach of the personal data protection of a person from
        the European Union, we would inform this person if this breach may actually have a serious
        impact on his/her rights, freedoms as well as privacy. In principle, legal provisions
        require us to inform two entities in the event of the breach of personal data protection:
        the appropriate supervisory authority and the personal data subject. At the same time, if
        the privacy of such a person and its other rights are not at risk (the law directly
        indicates the following cases: the controller implemented appropriate technical and
        organizational protection measures and these measures have been applied to the personal data
        the breach relates to, in particular measures such as encryption, preventing from reading by
        persons without authorized access to these personal data; the controller applied measures to
        eliminate the probability of the high risk of violation of the rights or freedoms of the
        data subject) there is no need to worry and in accordance with provisions of law we do not
        have to inform this person separately.
      </p>
      <p>
        If a notification of the breach of data protection which concerns person from the European
        Union would involve a disproportionate effort, a public communication is issued or a similar
        measure whereby the data subjects are informed about the breach in an equally effective
        manner.
      </p>

      <h2>LINKS TO OTHER SITES</h2>
      <p>
        Our Service may, from time to time, contain connections with or links to other sites that
        are not operated by us. If you click on a third-party link, you will be directed to that
        third party’s site or service. We advise you to review the privacy policy of every
        third-party service provider. We have no control over, and assume no responsibility for the
        content, privacy policies or practices of any third-party sites or services.{' '}
      </p>

      <h2>CHANGES TO THIS DATA PROTECTION POLICY</h2>
      <p>
        This Policy is effective as of 1 November 2018 and will remain in effect in its current
        wording until we amend or change it in any manner. In case of any changes – we will let you
        know about, either by email message or by posting the new Policy on our website, as
        reasonably practicable. We reserve the right to update or change our Policy at any time. If
        you continue to use our services after any change of Policy, it means that you agree with
        such changes.
      </p>

      <h2>A WEBSITE I VISIT USES LIVESESSION SAAS</h2>
      <p>
        Last but definitely not least - a word to those who visit our Client’s websites. If you read
        these words you may have had concerns about your privacy and the manner in which a website
        you have visited uses LiveSession online behaviour tracking tool. By this chapter of our
        Personal Data Policy we would like to provide you with relevant information on the how
        LivesSession SaaS works with one aim - to assure you that your privacy is protected in a
        right manner.
      </p>
      <p>
        LiveSession SaaS is the subscription-based software-as-a-service made available by
        LiveSession to website owners which gives them a tool to track a behaviour of their clients,
        customers, visitors etc. on their websites. It is the web-based analytical software which
        lets an website owner to replay website visitors’ actions. We have created this tool as it
        gives the great opportunities for the website owners to make their businesses better and by
        that - your online experience better too. Thanks to the tool we provide the website owner
        may have an insight into how you and other people use a given website - whether you can
        easily found ‘contact’ section or it takes dozens of clics to find a contact form; whether
        you are interested in new products or do you prefer to have a short read of company’s blog
        first. Information about all of those little choices are of paramount value to website
        owners, creators, designers and UX specialist. If they know about your behaviour at the
        website, they can easily make your experience better and more intuitive and, subsequently,
        e.g. make the e-shopping more comfortable and customer-friendly.
      </p>
      <p>
        Please kindly note that we provide the tool. This is always the website owner who decides
        what kind of information, and for what specific purposes, he or she needs, collects, process
        and uses. At LiveSession we are devoted to protect your privacy in appropriate manner and
        make sure that it is safe with us. We cooperate with our Clients to make sure that the use
        of our tools is beneficial to everyone. This is always, however, the website owner or
        provider who can help you in first place with any privacy-related questions or requests.
        Only he or she may provide you with the detailed information on what exactly is tracked,
        what kind of information is specifically collected, needed, processed etc.{' '}
      </p>
      <p>
        You may contact us at any time you wish and our promise is that we will do our best to help
        you with your privacy concerns. It may happen, however, that we would not be able to help
        you and therefore we recommend you to contact the website owner in the first place.
      </p>
      <p>
        We strongly advise you to carefully read the entire LiveSession Data Protection Policy. That
        will help you to understand how we protect the privacy.
      </p>
      <p>In addition, here are answers for several important questions:</p>

      <p>
        <strong>Is tracking online behaviour legal?</strong>{' '}
      </p>
      <p>
        Yes, it is and it is a common, world-wide practice. Many - if not all - popular websites
        track their visitors’ behaviour by various means. The legal requirements may however vary,
        depending on a state and jurisdiction. It is always the website owner’s (or provider etc.)
        responsibility to ensure that his or her website is in compliance with all due law and
        regulations.
      </p>

      <p>
        <strong>What kind of data LiveSession allows to collect?</strong>{' '}
      </p>
      <p>
        Our tools allows to track a website user’s behaviour from the first to the last clic on the
        website. By our tool our Clients track user’s activities what may include clics, their
        number, time spend of specific sites, content etc., and thus the personal information or
        personal data we collect include any information and data on your behaviour expressed by
        your online activity on that website. The LiveSession tool gives however our Clients the
        right to choose what kind of data is collected.
      </p>

      <p>
        <strong>I do not wish to be tracked, what should I do?</strong>{' '}
      </p>
      <p>
        Please contact the website owner - you should be advised whether and how you can opt out.
      </p>

      <p>
        <strong>
          What is the relation between LiveSession and the owner of website I visit? Who is
          responsible for what?
        </strong>{' '}
      </p>
      <p>
        LiveSession provides services to website owners on a basis of legal contract which is the
        Software-as-a-Service Agreement between us and our Clients. Part of that Agreement is the
        Personal Data Processing Agreement. While the main Agreement regulates the commercial terms
        and general duties and rights of the parties, the latter sets up clear rules on who is
        responsible for what when it comes to data processing and privacy protection. This is always
        the website owner (or provider) who collects that data and decided what to do with them.
        LiveSession has the access to all those data, but we never use them for our own purposes.
        From the legal perspective the data are collected by the website owner and we process them
        on his or her request. This is necessary for us to be able to offer and provide our SaaS.
      </p>

      <p>
        <strong>
          I don’t have time to read the entire document, but I have a question - how can I contact
          LiveSession?
        </strong>{' '}
      </p>
      <p>
        The easiest and fastest way is to send us an email to the following email address:
        privacy@livesession.io
      </p>
    </section>
  </Layout>
);

export default PP;
